<template>
  <div
    v-if="is_headquarters === 'true'"
    class="headquarters-wrap"
    :class="route.fullPath.includes('search') ? 'headquarters-grid' : ''"
  >
    <search-shop-pos v-if="route.fullPath.includes('search')"></search-shop-pos>
    <shop-pos-detail v-if="route.fullPath.includes('detail')"></shop-pos-detail>
    <shop-pos-list v-else></shop-pos-list>
  </div>
  <div v-else class="shop-wrap">
    <div>
      <p style="font-size: 1.6rem; font-weight: 500">暫時關閉「線上訂購」</p>
      <p style="font-size: 1.4rem; color: #b0afb0; margin-top: 8px">若暫時關閉線上訂購功能，將於隔日凌晨自動開啟。</p>
    </div>
    <el-switch
      v-if="outside_order_status !== null"
      v-model="outside_order_status"
      size="large"
      inline-prompt
      active-text="開"
      inactive-text="關"
      style="--el-switch-on-color: #059300; --el-switch-off-color: #db5959"
      @change="handleCloseOrder()"
    />
  </div>
</template>
<script>
import { defineAsyncComponent, ref, getCurrentInstance, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'set-shop-pos',
  components: {
    ShopPosList: defineAsyncComponent(() => import('@/components/shopSystem/ShopPosList.vue')),
    SearchShopPos: defineAsyncComponent(() => import('@/components/shopSystem/SearchShopPos.vue')),
    shopPosDetail: defineAsyncComponent(() => import('@/components/shopSystem/ShopPosDetail.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const outside_order_status = ref(null);

    //判斷總公司或門市帳號
    if (is_headquarters === 'true') {
      //總公司
      const doGetStoreList = useActions('common', ['doGetStoreList']);
      doGetStoreList();
    } else {
      //門市
      const doGetShopDetail = useActions('shopSystem', ['doGetShopDetail']);
      doGetShopDetail().then((res) => {
        outside_order_status.value = !res.close_outside_order;
      });
    }

    //門市功能
    const doSaveShopSetting = useActions('shopSystem', ['doSaveShopSetting']);
    const handleCloseOrder = async () => {
      outside_order_status.value = !outside_order_status.value;
      if (outside_order_status.value) {
        proxy
          .$confirm(`確定要暫時關閉線上訂購功能？<br />若關閉，將於隔日凌晨自動開啟`, '確認', {
            confirmButtonText: '關閉',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
            dangerouslyUseHTMLString: true,
          })
          .then(async () => {
            await doSaveShopSetting({ close_outside_order: true });
            outside_order_status.value = false;
          })
          .catch((e) => e);
      } else {
        await doSaveShopSetting({ close_outside_order: false });
        outside_order_status.value = true;
      }
    };

    //離開清空vuex
    const RESET = useMutations('shopSystem', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/system/shop')) RESET();
      }
    );
    return { route, is_headquarters, outside_order_status, handleCloseOrder };
  },
};
</script>
<style lang="scss" scoped>
.headquarters-wrap {
  width: 100%;
  height: 100%;
}
.headquarters-grid {
  display: grid;
  grid-template-rows: 96px auto;
  grid-gap: 16px;
}
.shop-wrap {
  @include white-bg;
  height: 100px;
  padding: 0 40px;
  @include flex-between-center;
}
</style>
